html {
  height: 100%;
}
body {
  height: 100%;
}
#root {
  height: 100%;
}
:root {
  --rv-blue: #f30213; 
}

.types {
  text-align: center;
  margin-bottom: 15px;
}

.buy-link-dialog .rv-dialog__confirm {
  background-color: #f30213;
  color: #fff;
}
