.express {
  height: 100%;
}

.express .items {
  margin: 20px 10px;
  padding-bottom: 80px;
}

.express .item-add {
  margin: 20px 10px;
  box-shadow: rgba(51, 51, 51, 0.15) 0px 2px 6px;
  padding: 15px 10px;
}

.express .item-add .input {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-bottom: 15px;
}

.express .items .item {
  box-shadow: rgba(51, 51, 51, 0.15) 0px 2px 6px;
  margin-bottom: 10px;
}

.express .items .item-add {
  display: flex;
  font-size: 22px;
  justify-content: center;
  padding: 20px 0px;
  align-items: center;
}

.express .items .item .item-header {
  font-size: 30px;
}

.express .items .item .item-body {
  font-size: 28px;
}

.express .items .item .item-footer button {
  font-size: 20px;
  padding: 20px 15px;
}

.express .rv-loading {
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
}
